import './App.css';
import Footer from "./components/Footer/Footer";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <Footer/>
            </header>
        </div>
    );
}

export default App;
