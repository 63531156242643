import React from 'react';
import styles from './Footer.module.css';
import {SocialItems} from "./SocialItems";

const Footer = () => (
    <div className={styles.FooterMainContainer}>
        <div className="container">
            <div className="row">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                    <div className={styles.FooterSocialDiv}>
                        {SocialItems.map((item, index) => {
                            return (
                                <a className={styles.FooterSocialA} key={index} href={item.url}
                                   target="_blank"
                                   id={`FooterSocial${item.title}`}>
                                    <i className={item.fontAwesome} title={item.title}/>
                                </a>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
