export const SocialItems = [
    {
        title: 'Facebook',
        url: 'https://www.facebook.com/TekiMart/',
        cName: '',
        fontAwesome: 'fab fa-facebook-f'
    },
    {
        title: 'Twitter',
        url: 'https://www.twitter.com/TekiMart',
        cName: '',
        fontAwesome: 'fab fa-twitter'
    },
    {
        title: 'LinkedIn',
        url: 'https://linkedin.com/company/tekimart/',
        cName: '',
        fontAwesome: 'fab fa-linkedin-in'
    },
    {
        title: 'Instagram',
        url: 'https://www.instagram.com/TekiMart',
        cName: '',
        fontAwesome: 'fab fa-instagram'
    },
    {
        title: 'YouTube',
        url: 'https://www.youtube.com/watch?v=e0N2yTQ3tVc',
        cName: '',
        fontAwesome: 'fab fa-youtube'
    }
]
